document.addEventListener('turbolinks:load', function (event) {
    if ($('.sessions.new').length === 0) {
        return false;
    }

    const togglePassword = document.querySelector('#togglePassword');
    const password = document.querySelector('#user_password');

    togglePassword.addEventListener('click', function (e) {
        if (password.type === "password") {
            password.type = "text";
            this.className = 'fa fa-eye';
        } else {
            password.type = "password";
            this.className = 'fa fa-eye-slash';
        }
    });
});
