document.addEventListener('turbolinks:load', function (event) {
    $('.main-header').css('width', $('.list-group').first().width());
    $('.main-header').find('th').each(function (index, theading) {
      let className = $(theading).attr('class');
      let maxWidth = Math.max.apply(Math, $('.main-header-items').map(function(){ return $(this).find(`.${className}`).outerWidth() }).get());
      $(theading).css('width', maxWidth);
    });

    if ($('.teams.teams_section').length === 0) {
      return false;
    }

    function handleSortEvt(evt) {
    let itemEl = evt.item;  // dragged HTMLElement
    let team_id = $(itemEl).data('id');
    let newIndex = evt.newIndex + 1;

    if (team_id) {
      const xhr = new XMLHttpRequest();
      xhr.open('PATCH', `/teams/${team_id}/reorder`);
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.send(JSON.stringify({
        authenticity_token: document.head.querySelector('meta[name="csrf-token"]').content,
        new_order: newIndex,
      }));
      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            JSON.parse(xhr.response).forEach(function (updates) {
              $(`#sn-${updates.id}`).html(updates.order);
            });
          }
        }
      }
    }
  }

  Sortable.create(sortableList, {
    onEnd: function (/**Event*/evt) {
      handleSortEvt(evt);

    }
  });

  Sortable.create(sortableList2, {
    onEnd: function (/**Event*/evt) {
      handleSortEvt(evt);
    }
  });
});
